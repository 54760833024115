<template>
  <div>
    <div class="con">
      <div class="nav">
        <div class="nitem back" @click="back">
          <img src="~@/assets/img/back.png" alt="" />
        </div>
        <div :class="act == 1 ? 'tabidx' : ''" class="nitem" @click="changeOpt(1)">
          <img src="~@/assets/img/sjhs.png" alt="" />
          <div class="mt5">基础配置</div>
        </div>
        <div :class="act == 2 ? 'tabidx' : ''" class="nitem" @click="changeOpt(2)">
          <img src="~@/assets/img/gjc.png" alt="" />
          <div class="mt5">商品配置</div>
        </div>
        <div :class="act == 3 ? 'tabidx' : ''" class="nitem" @click="changeOpt(3)">
          <img src="~@/assets/img/ckwa.png" alt="" />
          <div class="mt5">开播配置</div>
        </div>
        <!-- <div class="mt345">
          <div
            class="fs30"
            :style="{
              color: npzStatus == 'success' ? '#67C23A' : npzStatus == 'conduct' ? '#E6A23C' : '#F56C6C',
            }"
          >
            ●
          </div>
          <div class="fs14">
            {{ npzStatus == "success" ? "资源已创建" : npzStatus == "conduct" ? "资源创建中" : "资源未创建" }}
          </div>
        </div> -->
      </div>
      <div>
        <div v-if="act == 1">
          <first :liveId="id" :liveInfo="liveInfo" @next="next"></first>
        </div>
        <div v-if="act == 2">
          <second :liveId="id" :liveInfo="liveInfo"></second>
        </div>
        <div v-if="act == 3">
          <third :liveId="id" :liveInfo="liveInfo" @changeStatus="isOpen = !isOpen"></third>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myempty from "@/components/myempty.vue";
import first from "./liveOpt/first.vue";
import second from "./liveOpt/second.vue";
import third from "./liveOpt/third.vue";
import { get_ai_cfn, get_slive } from "@/api/slive.js";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";

export default {
  name: "createVideo",
  components: {
    myempty,
    first,
    second,
    third,
  },
  data() {
    return {
      act: 1,
      num: 1,
      id: null,
      isOpen: false,
      npzFile: false,
      inrerval: null,
      npzStatus: "fail",
      imgId: "",
      resPath: "",
      filePath: "",
      loadingText: " ",
      muteAudio: "",
      liveInfo: {},
    };
  },
  created() {
    /* 
      1.启动服务
      2.检验本地文件
    */
    this.id = this.$route.params.id;
    this.filePath = localStorage.getItem("basePath");
    this.getInfo();
    this.watchIpcSendMsg();
    this.getResPath();
  },
  methods: {
    getInfo() {
      get_slive({
        slive_id: this.id,
      }).then((res) => {
        if (res.code == "200") {
          this.liveInfo = res.data;
          localStorage.setItem("liveInfo", JSON.stringify(this.liveInfo));
          console.log("liveInfo", res.data, this.liveInfo);
          setTimeout(() => {
            this.checkLocalNpz(this.liveInfo);
          }, 200);
        } else {
          this.$message.error(res.msg);
        }
      });
      get_ai_cfn().then((res) => {
        if (res.code == "200") {
          localStorage.setItem("access_token", res.data.access_token);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    checkLocalNpz(e) {
      if (!e.url) {
        this.$message.error("请选择形象后生成资源");
        return;
      }
      const w2Resources = `${this.resPath}\\w2`;
      this.$ipc.invoke(ipcApiRoute.checkFileExistence, w2Resources).then((res) => {
        if (!res) {
          this.$message.error("当前资源目录不存在，请根据教程添加资源包");
          return;
        } else {
          const infer_save_path = `${this.filePath}\\video\\${this.liveInfo.image_id}_infer.mp4`;
          this.$ipc.invoke(ipcApiRoute.checkFileExistence, infer_save_path).then((r) => {
            if (r) {
              this.$notify.success({
                title: "提示",
                message: "形象资源已存在，可以开播",
              });
            } else {
              this.$notify.info({
                title: "提示",
                message: "未检测到形象资源，正在生成",
              });
              let params = {
                url: this.liveInfo.url,
                saveName: this.liveInfo.image_id + "_infer.mp4",
                filePath: this.filePath,
              };
              this.$ipc.invoke(ipcApiRoute.saveInferFile, params);
            }
          });
        }
      });
    },
    next(e) {
      if (!e.url) {
        this.$message.error("未查询到该形象的资源视频！");
      } else {
        this.act = 2;
        this.getInfo();
      }
    },
    back() {
      this.$router.back();
    },
    changeOpt(i) {
      if (this.isOpen) {
        this.$message.error("请先关闭直播！");
        return;
      }
      this.act = i;
    },
    getResPath() {
      this.$ipc.invoke(ipcApiRoute.getResPath).then((r) => {
        this.resPath = r;
      });
    },
    watchIpcSendMsg() {
      this.$ipc.removeAllListeners(ipcApiRoute.ipcSendMsg);
      this.$ipc.on(ipcApiRoute.ipcSendMsg, (event, res) => {
        console.log("ipcApiRoute.ipcSendMsg:", res);
        switch (res.type) {
          case "inferVideoSaveDone":
            this.$notify.success({
              title: "提示",
              message: "形象资源创建完成，可以开播",
            });
            break;
          /* case "infer_video_finish":
            console.log("监听到推理完成，正在重新校验本地资源");
            setTimeout(() => {
              this.$message.success("本地资源创建完成，正在校验资源完整性");
              console.log("liveInfo", this.liveInfo);

              this.checkLocalNpz(this.liveInfo);
            }, 500);
            break; */
          default:
            break;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.con {
  height: 93vh;
  background: #d6dff9;
  overflow: scroll;
  padding: 16px 13px 10px;
  display: flex;
  justify-content: space-between;

  .box {
    background: #f5f7fe;
    border-radius: 10px;
    height: 100%;
  }

  .nav {
    height: 100%;
    border-right: 2px solid #d7e0ed;
    background: #f5f7fe;
    border-radius: 10px;

    .nitem {
      background: #fefeff;
      border-radius: 10px;
      padding: 12px 6px;
      margin: 20px 12px;
      font-size: 14px;
      width: 75px;

      img {
        width: 56px;
        height: 56px;
      }
    }

    .back {
      img {
        width: 35px;
        height: 35px;
      }
    }

    .tabidx {
      border: 2px solid #4f81ff;
    }
  }
}
</style>
