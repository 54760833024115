<template>
  <div>
    <div class="con">
      <div class="box left">
        <div class="title">形象列表</div>
        <div v-if="figureList.length > 0" class="listBox">
          <div class="df f-w list">
            <div v-for="(item, idx) in figureList" :key="idx" @click="getItem(item, idx)" :class="index == idx ? 'choose' : ''" class="item">
              <img :src="item.cover" object-fit="cover" alt="" />
              <div class="desc">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-else style="margin: 0 auto">
          <myempty text="形象"></myempty>
        </div>
      </div>

      <div class="box center">
        <img v-show="activeInfo.cover" :src="activeInfo.cover" alt="" />
        <img v-show="!activeInfo.cover" src="../../../assets/img/noPic.jpg" alt="" />
        <div class="bottom" v-show="activeInfo.cover">
          <el-button type="primary" @click="next">保存</el-button>
        </div>
      </div>

      <div class="box right">
        <div class="box1 box2">
          <div class="title">音色设置</div>
          <div class="form">
            <el-form ref="form" :model="form" label-width="57px">
              <el-form-item label="分类">
                <el-select v-model="form.sort" clearable placeholder="请选择" @change="sortChange">
                  <el-option v-for="i in sortList" :label="i.name" :value="i.id" :key="i.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="音色">
                <el-select v-model="form.timbre" placeholder="请选择" @change="timbreChange">
                  <el-option v-for="i in timbreList" :label="i.name" :value="i.id" :key="i.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="语速">
                <div style="width: 93%">
                  <el-slider v-model="form.pace" :min="-5" :max="5" show-tooltip></el-slider>
                </div>
              </el-form-item>
              <el-form-item label="音调">
                <div style="width: 93%">
                  <el-slider v-model="form.tone" :min="-5" :max="5" show-tooltip></el-slider>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="success" @click="playMp3">试听音色</el-button>
                <!-- <el-button type="success" @click="testTTS">测试合成</el-button> -->
                <el-button type="success" @click="text">测试文案</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { get_image_list } from "@/api/video.js";
import { get_cat, gettimbre, getsound } from "@/api/audio.js";
import { edit_slive, get_slive, get_image_list } from "@/api/slive.js";
import myempty from "@/components/myempty.vue";
import { AudioSynthesis } from "./../tts";
import axios from "axios";

export default {
  name: "createVideo",
  components: {
    myempty,
  },
  props: {
    liveId: {
      type: Number,
      default: 0,
    },
    liveInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        pace: 0,
        tone: 0,
        sort: "",
        timbre: "",
      },
      index: null,
      name: "",
      audioSrc: "",
      audio: null,
      activetimbre: {},
      activeInfo: {},
      figureList: [],
      sortList: [],
      timbreList: [],
    };
  },
  watch: {
    liveInfo: {
      handler(n) {
        console.log(1111, this.liveInfo?.sound?.cat_id);

        if (this.liveInfo && this.liveInfo.sound) {
          console.log(2222, this.liveInfo?.sound?.cat_id);
          this.getFigure();

          this.sortChange(this.liveInfo.sound.cat_id);
          setTimeout(() => {
            this.form = {
              pace: this.liveInfo.sound.speech_rate / 100,
              tone: this.liveInfo.sound.pitch_rate / 100,
              sort: this.liveInfo.sound.cat_id,
              timbre: this.liveInfo.sound.sound_id,
            };
          }, 200);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.get_cat();
  },
  beforeDestroy() {
    if (this.audio) this.audio.pause();
  },
  methods: {
    testTTS() {
      let data = {
        text: "大家好，欢迎来到我们的直播间！今晚，我手里这份特价牛肉火锅套餐，绝对是冬日里的温暖神器，原价297元，现在只需197元，超值享受不容错过！",
        ...this.liveInfo.sound,
      };
      AudioSynthesis(data);
    },
    text() {
      // 我有一款商品，红烧肉。商品的信息有：商品价格：120,原价：188,商品描述:软嫩可口，肥而不腻。精选上等猪肉，慢火炖制，肉质鲜嫩多汁，入口即化，散发着浓浓的红烧肉香。搭配鲜蔬时蔬，清爽解腻，让你回味无穷。,商品亮点：精选上等猪肉，慢火炖制，肉质鲜嫩多汁，入口即化,商品规格:四人餐,适用人群:老少皆宜,优势：价格便宜,保障：随时退。我希望生成一段放大商品的亮点价值，激发购买欲的直播商品纯文字介绍文案。文案分成五段,每一段200字左右,以数组的形式返回文本
      axios
        .post(
          "https://aip.baidubce.com/rpc/2.0/ai_custom/v1/wenxinworkshop/chat/completions?access_token=" + localStorage.getItem("access_token"), //v3.5
          JSON.stringify({
            messages: [
              {
                role: "user",
                // content: `我有一款商品，红烧肉。商品的信息有：商品价格：120,原价：188,商品描述:软嫩可口，肥而不腻。精选上等猪肉，慢火炖制，肉质鲜嫩多汁，入口即化，散发着浓浓的红烧肉香。搭配鲜蔬时蔬，清爽解腻，让你回味无穷。,商品亮点：精选上等猪肉，慢火炖制，肉质鲜嫩多汁，入口即化,商品规格:四人餐,适用人群:老少皆宜,优势：价格便宜,保障：随时退。我希望生成一段放大商品的亮点价值，激发购买欲的商品纯文字介绍。文案200字左右`,
                content: `现在你是一位带货一哥，你需要根据我给出的商品信息撰写一段直播带货口播文案。你需要放大商品的亮点价值，激发购买欲。接下来是这款商品的信息：商品名称：红烧肉,商品价格：120,原价：188,商品描述:软嫩可口，肥而不腻。精选上等猪肉，慢火炖制，肉质鲜嫩多汁，入口即化，散发着浓浓的红烧肉香。搭配鲜蔬时蔬，清爽解腻，让你回味无穷。,商品亮点：精选上等猪肉，慢火炖制，肉质鲜嫩多汁，入口即化,商品规格:四人餐,适用人群:老少皆宜,优势：价格便宜,保障：随时退。生成五段相关联的文案每段200字左右，每段以/n/n结束，段落不需要添加提示，我需要根据/n/n在代码中进行分段处理`,
              },
            ],
            temperature: 0.95,
            top_p: 0.8,
            penalty_score: 1,
            disable_search: false,
            enable_citation: false,
            response_format: "text",
          })
        )
        .then((e) => {
          console.log(e.data.result);
          let arr = e.data.result.split("\n\n");
          arr.map((item) => item.replace(/\n\n/g, " "));
          console.log("arr", arr);
        });
    },
    sortChange(e) {
      gettimbre({ cat_id: e }).then((res) => {
        if (res.code == "200") {
          this.timbreList = [];
          this.timbreList.push(...res.data);
          this.form.timbre = "";
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    timbreChange(e) {
      this.timbreList.map((i) => {
        if (i.id == e) this.activetimbre = i;
      });
    },
    playMp3() {
      if (!this.activetimbre.id) {
        this.$message.warning("请选择要试听的音色！");
        return;
      }
      this.audio = new Audio(this.activetimbre.url); // 创建音频对象
      this.audio.play(); // 播放
    },
    next() {
      let params = {
        slive_id: this.liveId,
        image_id: this.activeInfo.id,
        sound_id: this.form.timbre,
        speech_rate: this.form.pace * 100,
        pitch_rate: this.form.tone * 100,
      };
      edit_slive(params).then((res) => {
        if (res.code == "200") {
          this.$emit("next", this.activeInfo);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getItem(e, idx) {
      this.activeInfo = e;
      this.index = idx;
    },
    getFigure() {
      get_image_list().then((res) => {
        if (res.code == "200") {
          this.figureList = res.data.list;
          this.figureList.map((i, idx) => {
            if (i.id == this.liveInfo.image_id) {
              this.activeInfo = i;
              this.index = idx;
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    get_cat() {
      get_cat().then((res) => {
        if (res.code == "200") {
          this.sortList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-textarea__inner {
  padding-bottom: 40px;
}

/deep/.el-textarea .el-input__count {
  background: #f3f5fd !important;
}

/deep/.el-table__body tr.current-row > td.el-table__cell {
  background: #80aaff !important;
}

.el-input__inner {
  border: 0px !important;
}

.con {
  height: 90vh;
  width: 87vw;

  background: #d6dff9;
  display: flex;
  justify-content: space-between;

  .box {
    background: #f5f7fe;
    border-radius: 10px;
    height: 100%;
  }

  .title {
    font-size: 15px;
    color: #547b9c;
    text-align: left;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .left {
    width: 25%;
    padding: 15px 10px 15px 27px;

    .listBox {
      height: 95%;
      overflow: scroll;
    }

    .list {
      .choose {
        border: 2px solid #4f81ff;
        height: 320px;
      }
    }
  }

  .center {
    margin: 0 12px;
    width: 468px;
    overflow: hidden;
    background: transparent;

    img {
      width: 468px;
      height: 816px;
      border-radius: 10px;
    }
  }

  .right {
    width: 29%;
    background: #f5f7fe;
    padding: 15px;

    .box1 {
      border-radius: 10px;
      overflow: hidden;
      color: #547b9c;
      font-size: 14px;
      background: #f5f7fe;
    }

    .act {
      color: #0362ee;
    }

    .tit {
      padding: 20px 12px;
      font-size: 18px;
    }

    .box2 {
      text-align: left;

      .form {
        height: 40vh;

        /deep/.el-select .el-input__inner:focus,
        /deep/.el-select .el-input__inner {
          border-color: #409eff;
          border: 0 !important;
        }

        /deep/.el-select > .el-input {
          width: 150%;
        }

        /deep/.el-slider__bar {
          background-color: #0362ee !important;
        }
      }
    }
  }
}

.bottom {
  margin-top: 15px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  /deep/.el-button {
    padding: 12px 50px !important;
    background: #347deb !important;
    border-radius: 10px !important;
  }
}

.item {
  margin-right: 25px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 5px;
  justify-content: space-between;
  height: 320px;
  overflow: hidden;
}

.item .desc {
  padding: 13px 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #f1f1f1;
}

.item img {
  width: 153px;
  height: 272px;
  border-radius: 5px 5px 0 0;
}

.item:last-child,
.item:nth-child(2n) {
  margin-right: 0;
}
</style>
