<template>
  <div class="conbox">
    <el-upload
      ref="audioUpload"
      class="avatar-uploader"
      :show-file-list="false"
      :action="actions"
      accept="image/*"
      multiple
      :limit="1"
      :before-upload="beforeAvatarUpload"
      :http-request="uploadFile"
      :on-preview="preview"
      :on-exceed="exceed"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
import axios from "axios";
import { getOss } from "@/api/user.js";
import { formatDate, randomText } from "@/utils/tools";

export default {
  name: "UploadFile",
  props: {
    logo: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      actions: process.env.VUE_APP_API_BASE_URL + "/api/local_doc_qa/upload_file",
      imageUrl: "",
      data: {
        token: localStorage.getItem("token"),
      },
    };
  },
  watch: {
    logo(val) {
      this.imageUrl = val;
    },
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      var regex = /^.+\.(jpg|jpeg|png|gif|bmp)$/i;
      if (!regex.test(file.name)) this.$message.error("请上传图片!");
      if (!isLt2M) this.$message.error("上传头像图片大小不能超过 2MB!");
      return regex.test(file.name) && isLt2M;
    },
    async uploadFile({ file }) {
      console.log("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传文件中，时间较长请耐心等待",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let date = formatDate(new Date());
      let name = randomText() + ".mp4";
      let dir = `upload/audio/${date}/${name}`;
      await getOss({ dir }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          let form = new FormData();
          form.append("success_action_status", "200");
          form.append("key", dir);
          form.append("OSSAccessKeyId", res.data.accessid);
          form.append("policy", res.data.policy);
          form.append("signature", res.data.signature);
          form.append("file", file);
          axios({
            url: res.data.host,
            method: "POST",
            data: form,
            withCredentials: false,
          }).then((r) => {
            loading.close();
            this.imageUrl = `${res.data.host}/${dir}`;
            this.$message.success("上传成功");
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    clearFile() {
      this.$refs.audioUpload._data.uploadFiles = [];
    },

    preview(e) {
      console.log(e);
    },

    exceed(e) {
      console.log("exceed", e);
      this.uploadFile({ file: e[0] });
      // this.$message.error("最大允许上传1个文件！");
    },

    aliOssUpload(file) {},
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
